import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import * as dayjs from 'dayjs';
import axios from 'axios';

import { useContext } from 'react';
import { GlobalContext } from '../App';
import { defaultUserInformationsState } from '../appState/default-user-information-state';
import { TOAST_TYPE } from '../hooks/use-toast';
import { dateFormats } from '../shared/constants';
import { TIME_DELAY, API_URL } from '../conf';

export const SearchUserInfo = ({ text, action, value, searchBy }) => {
  const { setUserInformations, toast, userInformations } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const { triggerToast } = toast;

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await axios.post(
        `${API_URL}/api/scu/search/${searchBy}`,
        { searchValue: value, token: localStorage.getItem('token') }
      );
  
      if (response.data.success) {
        const userData = response.data.data[0];
        const parsedFirstRegistrationDate = dayjs(userData.first_registration_date).format(dateFormats.dots);
  
        try {
          const scrapResponse = await axios.post(`${API_URL}/api/kukosoft/scrap`, {
            registrationNumber: userData.reg_no.replace(/\s+/g, ''),
            firstRegistrationDate: parsedFirstRegistrationDate,
            vin: value
          });
  
          const scrapInfo = scrapResponse.data;
          const inspectionDate = scrapInfo.nextTechReviewDate?.split('.').reverse().join('-');
          const insuranceDate = scrapInfo.policyExpiryDate?.split('.').reverse().join('-');
  
          triggerToast('Wczytano dane klienta', TOAST_TYPE.SUCCESS);
  
          setUserInformations({
            phone: userData.phone_no,
            firstName: userData.first_name,
            lastName: userData.surname,
            inspectionDate: inspectionDate || dayjs(userData.next_tech_exam_date).format(dateFormats.default) || '',
            insuranceDate: insuranceDate || dayjs(userData.insurance_date_to).format(dateFormats.default) || '',
            hasInsurance: userData.has_issued_policy,
            policies: userData.policies,
            existingUser: true,
            vehicleId: userData.vehicle_id,
            hasOutdatedData: userInformations.hasOutdatedData,
            registryNumber: userData.reg_no,
          });
  
        } catch (scrapError) {
          console.warn('Scraper request failed, using fallback data', scrapError);
          triggerToast('Scraper nie działa - używam domyślnych danych', TOAST_TYPE.WARNING);
  
          setUserInformations({
            phone: userData.phone_no,
            firstName: userData.first_name,
            lastName: userData.surname,
            inspectionDate: dayjs(userData.next_tech_exam_date).format(dateFormats.default) || '',
            insuranceDate: dayjs(userData.insurance_date_to).format(dateFormats.default) || '',
            hasInsurance: userData.has_issued_policy,
            policies: userData.policies,
            existingUser: true,
            vehicleId: userData.vehicle_id,
            hasOutdatedData: userInformations.hasOutdatedData,
            registryNumber: userData.reg_no,
          });
        }
  
      } else {
        triggerToast('Nie znaleziono klienta w systemie', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
        setUserInformations(defaultUserInformationsState);
      }
  
    } catch (error) {
      triggerToast('Nie udało się pobrać danych klienta - błąd serwera', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="d-flex flex-column justify-content-between">
      <Form onSubmit={handleClick} className="d-flex flex-column justify-content-between">
        <Form.Group>
          <h2 className="registry-number-label">{text}</h2>
          <Form.Control
            type="text"
            onChange={(e) => action(e.currentTarget.value)}
            className="search-user-input text-uppercase"
          />
        </Form.Group>
        <Button className="search-user-button" onClick={handleClick}>
          {isLoading ? (
            <div className="spinner-container">
              <Spinner as="span" animation="border" size="m" role="status" aria-hidden="true" />
              <h2 className="spinner-text color-white d-inline ml-8">
                Wyszukiwanie...
              </h2>
            </div>
          ) : (
            <h2 className="search-text color-white">
              Wyszukaj
            </h2>
          )}
        </Button>
      </Form>
    </div>
  );
};
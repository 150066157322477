import * as dayjs from 'dayjs';
import axios from 'axios';

import { Scanner } from '../../components/scanner.component';
import { useContext } from 'react';
import { GlobalContext } from '../../App';
import { routes, API_URL, TIME_DELAY } from '../../conf';
import { defaultUserInformationsState } from '../../appState/default-user-information-state';
import { TOAST_TYPE } from '../../hooks/use-toast';
import { dateFormats } from '../../shared/constants';

export const ScannerPage = () => {
  const { setUserInformations, location, toast, speedCarWorker, setTechnicalReviewId, hasOutdatedData } = useContext(GlobalContext);
  const { person_id, contractor_head, contractor_service } = speedCarWorker;
  const { triggerToast } = toast;

  const handleScanAztec = async (aztec) => {
    try {
      const clientData = await axios.post(API_URL + '/api/kukosoft/decode', { aztec }).then((response) => {
        return response;
      });
      const {
        seria_dr,
        organ_wydajacy_nazwa,
        organ_wydajacy_ulica,
        organ_wydajacy_miasto,
        nr_rejestracyjny,
        marka_pojazdu,
        typ_pojazdu,
        wariant_pojazdu,
        wersja_pojazdu,
        model_pojazdu,
        vin_nr_nadwozia,
        data_wydania_aktualnego_dr,
        nazwa_posiadacza_dr,
        imiona_posiadacza_dr,
        nazwisko_posiadacza_dr,
        pesel_regon_posiadacza_dr,
        kod_pocztowy_posiadacza_dr,
        miasto_posiadacza_dr,
        powiat_posiadacza_dr,
        ulica_posiadacza_dr,
        nr_lokalu_posiadacza_dr,
        nazwa_wlasciciela_pojazdu,
        imiona_wlasciciela_pojazdu,
        nazwisko_wlasciciela_pojazdu,
        pesel_regon_wlasciciela_pojazdu,
        kod_pocztowy_wlasciciela_pojazdu,
        miasto_wlasciciela_pojazdu,
        powiat_wlasciciela_pojazdu,
        ulica_wlasciciela_pojazdu,
        nr_domu_wlasciciela_pojazdu,
        nr_domu_posiadacza_dr,
        nr_lokalu_wlasciciela_pojazdu,
        maksymalna_masa_calkowita,
        dopuszczalna_masa_calkowita_pojazdu,
        dopuszczalna_masa_calkowita_zespolu_pojazdow,
        masa_wlasna,
        kategoria_pojazdu,
        nr_swiadectwa_homologacji_typu_pojazdu,
        liczba_osi,
        maksymalna_masa_calkowita_przyczepy_z_hamulcem,
        maksymalna_masa_calkowita_przyczepy_bez_hamulca,
        stosunek_mocy_do_ciezaru,
        pojemnosc,
        moc_silnika,
        rodzaj_paliwa,
        data_pierwszej_rejestracji,
        miejsca_siedzace,
        miejsca_stojace,
        rodzaj_pojazdu,
        przeznaczenie,
        rok_produkcji,
        najwiekszy_dopuszczalny_nacisk_osi,
        nr_karty_pojazdu,
        kod_its,
      } = clientData.data;

      const parsedFirstRegistrationDate = dayjs(data_pierwszej_rejestracji).format(dateFormats.dots);
    
      let scrapInfo = null;
      
      try {
        const scrapResponse = await axios.post(API_URL + '/api/kukosoft/scrap', {
          registrationNumber: nr_rejestracyjny.replace(/\s+/g, ''),
          firstRegistrationDate: parsedFirstRegistrationDate,
          vin: vin_nr_nadwozia
        });
        scrapInfo = scrapResponse.data;
      } catch (error) {
        console.warn('Scraper request failed, using fallback data', error);
        triggerToast('Scraper nie działa - używam domyślnych danych', TOAST_TYPE.WARNING);
      }

      const inspectionDateCEPIK = scrapInfo?.nextTechReviewDate?.split('.').reverse().join('-');
      const insuranceDateCEPIK = scrapInfo?.policyExpiryDate?.split('.').reverse().join('-');
      const hasLpg = scrapInfo?.hasLpg;

      const addClientResponse = await axios.post(API_URL + '/api/scu/add-client', {
        phone_no: '',
        next_tech_exam_date: '',
        insurance_date_to: '',
        has_outdated_data: hasOutdatedData,
        has_technical_review: false,
        vehicle_id: null,
        aztec_data: {
          SeriaDR: seria_dr,
          OrganWydajacy1: organ_wydajacy_nazwa,
          OrganWydajacy2: organ_wydajacy_ulica,
          OrganWydajacy3: organ_wydajacy_miasto,
          A: nr_rejestracyjny,
          D_1: marka_pojazdu,
          D_2_1: typ_pojazdu,
          D_2_2: wariant_pojazdu,
          D_2_3: wersja_pojazdu,
          D_3: model_pojazdu,
          E: vin_nr_nadwozia,
          I: data_wydania_aktualnego_dr,
          C_1_1: nazwa_posiadacza_dr,
          C_1_1_Imiona: imiona_posiadacza_dr,
          C_1_1_Nazwisko: nazwisko_posiadacza_dr,
          C_1_2: pesel_regon_posiadacza_dr,
          C_1_3_KodPocztowy: kod_pocztowy_posiadacza_dr,
          C_1_3_Poczta: miasto_posiadacza_dr,
          C_1_3_Miejscowosc: powiat_posiadacza_dr,
          C_1_3_Ulica: ulica_posiadacza_dr,
          C_1_3_Lokal: nr_lokalu_posiadacza_dr,
          C_1_3_Dom: nr_domu_posiadacza_dr,
          C_2_1: nazwa_wlasciciela_pojazdu,
          C_2_1_Imiona: imiona_wlasciciela_pojazdu,
          C_2_1_Nazwisko: nazwisko_wlasciciela_pojazdu,
          C_2_2: pesel_regon_wlasciciela_pojazdu,
          C_2_3_KodPocztowy: kod_pocztowy_wlasciciela_pojazdu,
          C_2_3_Poczta: miasto_wlasciciela_pojazdu,
          C_2_3_Miejscowosc: powiat_wlasciciela_pojazdu,
          C_2_3_Ulica: ulica_wlasciciela_pojazdu,
          C_2_3_Dom: nr_domu_wlasciciela_pojazdu,
          C_2_3_Lokal: nr_lokalu_wlasciciela_pojazdu,
          F_1: maksymalna_masa_calkowita,
          F_2: dopuszczalna_masa_calkowita_pojazdu,
          F_3: dopuszczalna_masa_calkowita_zespolu_pojazdow,
          G: masa_wlasna,
          J: kategoria_pojazdu,
          K: nr_swiadectwa_homologacji_typu_pojazdu,
          L: liczba_osi,
          O_1: maksymalna_masa_calkowita_przyczepy_z_hamulcem,
          O_2: maksymalna_masa_calkowita_przyczepy_bez_hamulca,
          Q: stosunek_mocy_do_ciezaru,
          P_1: pojemnosc,
          P_2: moc_silnika,
          P_3: hasLpg? 'P LPG' : rodzaj_paliwa,
          B: data_pierwszej_rejestracji,
          S_1: miejsca_siedzace,
          S_2: miejsca_stojace,
          RodzajPojazdu: rodzaj_pojazdu,
          Przeznaczenie: przeznaczenie,
          RokProdukcji: rok_produkcji,
          NajwiekszyDopNaciskOsi: najwiekszy_dopuszczalny_nacisk_osi,
          NumerKartyPojazdu: nr_karty_pojazdu,
          ITS_Id: kod_its,
        },
        aztec_code: aztec,
        person: {
          person_id,
          contractor_head,
          contractor_service
        },
        token: localStorage.getItem('token'),
      });

      const technicalReviewId = addClientResponse.data.data.technical_review;
      setTechnicalReviewId(technicalReviewId);
      
      const insuranceDateSCU = dayjs(addClientResponse.data.data.vehicle.insurance_date_to).format(dateFormats.default);
      const insuranceDate = insuranceDateCEPIK || insuranceDateSCU || '';
      const inspectionDateSCU = dayjs(addClientResponse.data.data.vehicle.next_tech_exam_date).format(dateFormats.default);
      const inspectionDate = inspectionDateCEPIK || inspectionDateSCU || '';

      if (addClientResponse.data.success) {
        triggerToast('Dodano nowego klienta', TOAST_TYPE.SUCCESS);
        setUserInformations({
          phone: addClientResponse.data.data.vehicle.tel,
          firstName: addClientResponse.data.data.cl_contractor.first_name,
          lastName: addClientResponse.data.data.cl_contractor.surname,
          inspectionDate: inspectionDate,
          insuranceDate: insuranceDate,
          policies: [],
          existingUser: true,
          vehicleId: addClientResponse.data.data.vehicle.id,
          hasOutdatedData: hasOutdatedData,
          registryNumber: addClientResponse.data.data.vehicle.reg_no,
          hasInsurance: addClientResponse.data.data.has_issued_policy,
          scanId: addClientResponse.data.data.scan_id,
        });
      } else {
        triggerToast('Nie udało się dodać klienta - błąd wewnętrzny serwera', TOAST_TYPE.DANGER);
      }
    } catch (error) {
      console.error('Error in addNewClient:', error);
      triggerToast('Nie udało się dodać klienta - błąd zewnętrzny serwera', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
    }
  };

  const fetchDataByRegNo = async (aztec) => {
    const registryNumber = await axios.post(API_URL + '/api/kukosoft/decode', { aztec }).then((response) => {
      const registryNumberWithoutSpaces = response.data.nr_rejestracyjny.replace(/ /g, '');
      return registryNumberWithoutSpaces;
    });
    await axios
      .post(API_URL + '/api/scu/search/registryNumber', { searchValue: registryNumber, token: localStorage.getItem('token') })
      .then((response) => {
        const responseStatus = response.data.success;
        if (responseStatus) {
          const userData = response.data.data[0];
          triggerToast('Wczytano dane klienta', TOAST_TYPE.SUCCESS);
          setUserInformations({
            phone: userData.phone_no,
            firstName: userData.first_name,
            lastName: userData.surname,
            inspectionDate: dayjs(userData.next_tech_exam_date).format(dateFormats.default),
            insuranceDate: dayjs(userData.insurance_date_to).format(dateFormats.default),
            hasInsurance: userData.has_issued_policy,
            policies: userData.policies,
            existingUser: true,
            vehicleId: userData.vehicle_id,
            hasOutdatedData: hasOutdatedData,
            registryNumber: userData.reg_no
          });
        } else {
          triggerToast('Nie znaleziono klienta w systemie', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
          setUserInformations(defaultUserInformationsState);
        }
      })
      .catch(() => {
        triggerToast(
          'Nie udało się pobrać danych klienta - błąd zewnętrzny serwera',
          TOAST_TYPE.DANGER,
          TIME_DELAY.LONG
        );
      });
  };

  const fetchPoliciesByReg = async (aztec) => {
    const registryNumber = await axios.post(API_URL + '/api/kukosoft/decode', { aztec }).then((response) => {
      const registryNumberWithoutSpaces = response.data.nr_rejestracyjny.replace(/ /g, '');
      return registryNumberWithoutSpaces;
    });
    await axios
      .post(API_URL + '/api/scu/get-policies', {
        reg_no: registryNumber,
        token: localStorage.getItem('token')
      })
      .then((response) => {
        const responseStatus = response.data.success;
        if (responseStatus) {
          const userData = response.data.data;
          userData.length > 0
            ? triggerToast('Wczytano dostępne polisy', TOAST_TYPE.SUCCESS)
            : triggerToast('Użytkownik nie posiada polis', TOAST_TYPE.DANGER);
          setUserInformations({
            policies: userData
          });
        } else {
          triggerToast('Nie znaleziono klienta w systemie', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
          setUserInformations({ policies: [] });
        }
      })
      .catch(() => {
        triggerToast('Nie udało się pobrać danych klienta - błąd serwera', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
      });
  };

  const callToBackendFunctions = {
    [routes.inspectionInitPage]: handleScanAztec,
    [routes.scanRodoRequest]: fetchDataByRegNo,
    [routes.insuranceInitPage]: handleScanAztec,
    [routes.policyDocumentationInitPage]: fetchPoliciesByReg
  };

  const callToBackend = callToBackendFunctions[location];

  return <Scanner callToBackend={callToBackend} />;
};
